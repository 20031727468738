<template>
  <div class="flex flex-1 flex-col">
    <portal to="navbar-title">Parcelle</portal>
    <Popup v-if="show_validate_modal" @close="show_validate_modal = false">
      <h2 class="text-2xl font-bold py-2 font-main" slot="title">
        Confirmation
      </h2>
      <p class="text-base font-normal text-promy-gray-250" slot="body">
        Voulez-vous sauvegarder votre sélection avant de quitter cette page ?
      </p>
      <div slot="buttons">
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
          @click="saveSelectionCrossConfirmation"
        >
          Oui
        </button>
        <button
          @click="cancelSaveSelectionCrossConfirmation"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Non
        </button>
      </div>
    </Popup>
    <LayoutMapAndTools withToolBar :initOpenToolBar="false">
      <template #outils>
        <Altimetries ref="refAltimetries"></Altimetries>
      </template>
      <!-- <template #actions-header>
        <div class="flex lg:flex-col items-end">
          <div
            class="
              flex
              desktop:space-x-6
              xl:flex-col xl:space-y-2 xl:space-x-0
              lg:flex-col lg:space-y-2
            "
            v-if="toggle_actions"
          >
            <AggregateParcelle
              @addMakerslengthsWall="addMakerslengthsWall"
              @resetDetailsParcelles="resetDetailsParcelles"
              @updateAddress="updateAddress"
              ref="aggregateParcelle"
            />
          </div>
        </div>
      </template>
      -->
      <template #footer-details-infos>
        <div class="flex flex-col w-[45rem] lg:w-full relative">
          <div
            v-if="!own_parcelles.length"
            class="font-bold text-white bg-promy-orange-100 p-6 rounded-full w-fit sm:w-full absolute top-[-5.2rem] left-1/2 transform -translate-x-1/2 text-center sm:py-4 sm:top-[-4.2rem]"
          >
            Merci de sélectionner une parcelle
          </div>
          <DetailsParcelles
            ref="detailsParcelles"
            :adresse="address.information.properties.label"
          />
        </div>
      </template>
      <template #layers>
        <MapType :layers="layers" />
      </template>
      <template #map>
        <Map
          @addMakerslengthsWall="addMakerslengthsWall"
          @zoomEnd="zoom_end = true"
          @updateAddress="updateAddress"
        ></Map>
        <div
          v-if="is_address_changing && next_route"
          class="bg-loader flex justify-center items-center absolute w-full h-full top-0 left-0 z-10"
        >
          <LoaderPromy
            classes="w-6"
            :size_bg_promy="0"
            :color="'#ffffff'"
            class="items-center justify-center flex"
          ></LoaderPromy>
        </div>
      </template>
    </LayoutMapAndTools>
  </div>
</template>

<script>
import AggregateParcelle from './Actions/AggregateParcelle.vue'
import Altimetries from './Actions/Altimetries.vue'
import LayoutMapAndTools from '../LayoutMapAndTools.vue'
import DetailsParcelles from './DetailsParcelles.vue'
import Map from './map.vue'
import { mapGetters } from 'vuex'
import parcelle from '@/mixins/parcelle'
import Mapbox from '@/mixins/map/mapbox'
import batiment from '@/mixins/batiment'
import axios from 'axios'
export default {
  components: {
    LayoutMapAndTools,
    AggregateParcelle,
    DetailsParcelles,
    Map,
    Altimetries,
  },
  mixins: [parcelle, batiment, Mapbox],
  computed: {
    ...mapGetters({
      features_autour: 'parcelle/features_autour',
      parcelles_autour: 'parcelle/parcelles_autour',
      current_parcelle: 'parcelle/current_parcelle',
      parcelles_voisines: 'parcelle/parcelles_voisines',
      map: 'map/map',
      address: 'address/address',
      is_parcelle_changing: 'parcelle/is_parcelle_changing',
      is_parcelle_aggregating: 'parcelle/is_parcelle_aggregating',
      current_batiments: 'parcelle/current_batiments',
      isLoggedIn: 'auth/isLoggedIn',
      is_address_changing: 'address/is_address_changing',
      infos_commune: 'address/infos_commune',
    }),
  },
  data() {
    return {
      markers_lengths_walls: [],
      toggle_actions: true,
      layers: [],
      next_route: null,
      show_validate_modal: false,
      save_parcelle: false,
      id_time_out_get_address: null,
      source_cancel_token: null,
    }
  },
  created() {
    this.layers = [
      {
        label: 'Cadastre',
        image: 'Cadastre.svg',
        is_active: false,
        component: 'CadastreOfficiel',
        data: {
          code_insee: this.infos_commune.code_insee,
        },
      },
    ]
  },
  methods: {
    toggleActionsHandler() {
      this.toggle_actions = !this.toggle_actions
    },
    cancelSaveSelectionCrossConfirmation() {
      this.$store.commit('parcelle/IS_PARCELLE_AGGREGATING', false)
      this.$store.commit('parcelle/IS_PARCELLE_CHANGING', false)
      this.show_validate_modal = false
      this.$store.commit('parcelle/CANCEL_SAVE_SELECTION_PARCELLES', true)
      this.$router.push(this.next_route)
    },
    saveSelectionCrossConfirmation() {
      this.is_parcelle_changing
        ? this.$refs.changeParcelle.addListenerClickChangeParcelle()
        : this.$refs.aggregateParcelle.addListenerClickAggregateParcelle()
    },

    resetDetailsParcelles() {
      this.$store.commit(
        'parcelle/CURRENT_BATIMENTS',
        this.getAllBatimentsWithUniqueId(
          this.getFeaturesExist(this.features_autour, this.own_parcelles),
          this.own_parcelles,
        ),
      )
      this.$refs.detailsParcelles.init()
    },
    addMakerslengthsWall(geojson) {
      this.markers_lengths_walls = this.getAndAddMakerslengthsWallsToMap(
        geojson ? this.getTailleMurs(geojson) : [],
        this.markers_lengths_walls,
      )
    },
    updateAddress() {
      this.show_validate_modal = false
      let information = JSON.parse(JSON.stringify(this.address.information))
      information.geometry.coordinates = this.$turf
        .pointOnFeature(this.current_parcelle)
        .geometry.coordinates.map((coord) => coord.toString())

      let data = {
        information: information,
        ref_cadastrale_parcelle_principale: this.current_parcelle.properties.id,
        refs_cadastrale_parcelles_voisines: this.parcelles_voisines.map(
          (parcelle) => parcelle.properties.id,
        ),
      }
      this.$store.commit('address/SET_IS_ADDRESS_CHANGING', true)
      if (this.id_time_out_get_address) {
        clearTimeout(this.id_time_out_get_address)
      }

      this.source_cancel_token && this.source_cancel_token.cancel()
      const CancelToken = axios.CancelToken
      this.source_cancel_token = CancelToken.source()
      if (this.isLoggedIn) {
        this.$store.commit('address/IS_PARCELLE_PRINCIPALE_CHANGED', true)
        this.$http
          .put(
            `/grand-publics/adresses/${this.$route.params.id_address}`,
            data,
            {
              cancelToken: this.source_cancel_token.token,
            },
          )
          .then(() => {
            this.id_time_out_get_address = setTimeout(() => {
              this.$http.get(
                `/grand-publics/adresses/${this.$route.params.id_address}`,
              )
            }, 5000)

            this.$store.commit('address/SET_IS_ADDRESS_CHANGING', false)

            this.$http
              .put(
                `/grand-publics/update-label-address/${this.$route.params.id_address}`,
                data,
              )
              .then((response) => {
                this.address.information.properties.label =
                  response.data.address.label
              })
          })
      } else {
        this.$store.commit('address/IS_PARCELLE_PRINCIPALE_CHANGED', true)
        this.$store.commit('address/SET_IS_ADDRESS_CHANGING', false)
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.own_parcelles.length && to.name !== 'Login') {
      this.toast(
        'Avertissement',
        'Merci de sélectionner une parcelle',
        'warning',
      )
    } else {
      this.removeMarkers(this.markers_lengths_walls)
      this.$destroy()

      next()
    }
  },
}
</script>
<style scoped>
.bg-loader {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
